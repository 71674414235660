@media screen and (max-width: 1024px) {

    @keyframes appear {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

.Intro {
    position: absolute;
    transition: .25s ease;
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100%;
    opacity: 0;
    transition-delay: 2s;
}

.intro .Dynamic-box {
    background-color: black;
}

.Intro h2 {
    font-size: 13px;
    letter-spacing: 5.72px;
    margin-top: 10px;
    position: absolute;
    opacity: 0;
    z-index: 1;
    animation: appear .74s ease forwards;
    animation-delay: .5s;
}

.intro .Intro svg {
    opacity: 1;
}

.Intro svg {
    opacity: 0;
    transition: 1s;
    align-self: center;
    width: 100%;
}

.intro .Intro {
    opacity: 1;
    transition-delay: unset;
}

}