header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    z-index: 3;
}

header .main-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

header .wrapper {
    display: flex;
}

header .logo-box {
    display: flex;
}

header path {
    fill: var(--green-color);
}

header .insta path {
    fill: none;
}

header h1 {
    margin-left: 15px;
}

header sup {
    font-size: 13px;
    color: #565656;
}


header .language {
    /* display: flex; */
    align-items: center;
    display: none;
}

header .language button {
    color: white;
    letter-spacing: 1px;
    font-size: 14px;
}

header .language p {
    margin:0 15px;
    color: var(--grey-color);
}

header .burger {
    display: block;
    height: 21px;
    width: 31px;
    margin-left: 60px;
}

header .burger-line:after,
header .burger-line,
header .burger-line:before {
    background-color: var(--green-color);
    display: block;
    width: 100%;
    height: 4px;
    content: '';
    margin: 6px 0;
}

header .socials {
    width: 120px;
    display: flex;
    justify-content: space-between;
}

/* header .socials .insta {
    opacity: 0;
} */

header .socials a {
    height: 140%;
    display: flex;
    justify-content: flex-end;
}

header .socials a:first-child {
    width: 65%;
}

header .socials a:last-child {
    width: 35%;
}

header .socials a svg {
    width: 27px;
    height: 27px;
}

header .socials svg rect {
    stroke: var(--green-color);
    transition: .74s ease;
}

header .socials .insta path {
    stroke: var(--green-color);
}

header .socials a svg path {
    transition: .5s ease;
}

/* Media */

@media screen and (min-width: 1024px) {


    header .socials a:hover svg rect,
    header .socials a:hover svg g,
    header .socials a:hover .insta path {
    stroke: black;
    }

    header .socials a:hover svg path:not(.insta path) {
    fill: black;
    }
}



@media screen and (max-width: 1024px) {

    header {
        height: 50px;
        transition: .34s ease;
    }
  
    header h1 {
        height: fit-content;
        margin-left: 12px;
    }

    header sup {
        font-size: 11px;
        color: #565656;
    }

    header svg {
        height: 22.61px;
        width: 25.44px;
        position: relative;
        top: 1px;
    }

    header .main-wrapper {
        height: fit-content;
        padding: 12px 6px;
        opacity: 0;
        animation: appear .74s ease forwards;
        animation-delay: 2.35s;
    }

    .intro header .main-wrapper {
        animation: none;
        opacity: 0;
    }

    @keyframes appear {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    header .logo-box {
        height: fit-content;
    }

    header .wrapper {
        height: fit-content;
        align-self: center;
        position: relative;
        bottom: 2px;
    }

    header .burger {
        height: 12px;
        width: 26px;
        margin-left: 0;
    }

    header .burger-line:after, header .burger-line, header .burger-line:before {
        width: 100%;
        height: 3px;
    }

    header .burger-line,
    header .burger-line:before {
        margin-top: 0;
    }

    header .burger-line:after {
        margin-bottom: 0;
    }

    header .socials {
        width: 80px;
    }

    header .socials a {
        height:fit-content;
    }

    header .socials a svg {
        width: 25px;
        height: 25px;
    }

    header .socials a:first-child {
        width: 50%;
    }
    
    header .socials a:last-child {
        width: 50%;
    }
}