.main-card.back {
    transform: rotateY(180deg);
    transform-style: preserve-3d;
    backface-visibility: hidden;
    border-color: #2C2C2C;
}

.main-card.back .border {
    width: 91.758%;
    height: 95.695%;
}

.main-card.back img:not(.main-card.back .light, .zodiac, .element) {
    position: absolute;
    z-index: 2;
}


.main-card.back p {
    font-size: 15px;
    width: auto;
    font-family: 'Runic';
}

.main-card.back div p {
    font-size: 24px;
    font-family: 'Roboto Custom';
    font-weight:bold;
    padding-left: 2px;
}

.main-card.back div .card-number {
    font-size: 15px;
    font-family: 'Raleway Regular';
}

.main-card.back div:first-of-type {
    top: 6%;
    z-index: 2;
}

.main-card.back div:last-of-type {
    bottom: 7.5%;
}

.main-card.back .symbol {
    font-size: 170px;
    align-self: center;
    z-index: 2;
    line-height: auto;
    position: absolute;
}

.third .main-card.back p {
    opacity: 1;
    z-index: 2;
}

.main-card.back div {
    display: flex;
    flex-direction: row;
    position: absolute;
    justify-content: space-between;
    width: 76%;
    padding-left: 3px;
}

.main-card.back .card-name {
    justify-self: center;
    text-transform: uppercase;
    letter-spacing: 6.6px;
    font-size: 15px;
    padding-left: 9px;
}

.main-card.back div:nth-child(2).card-name {
    font-family: 'Raleway';
}

.main-card.back .card-name:nth-child(6) {
    position: inherit;
    top: 81.5%;
}

.main-card.back div:first-of-type .name {
    position: relative;
    top: 2px;
}

.main-card.back .card-name:first-of-type {
    letter-spacing: 39.5%;
}

.main-card.back p:nth-child(6) {
    font-family: 'Runic';
}

.main-card.back .light {
    align-self: center;
    z-index: -1;
}

@keyframes flipback {
    from {
        transform: rotateY(180deg);
    }
    to {
        transform: rotateY(0deg);
    }
}

.third .main-card.back {
    animation: flipback .74s ease;
    animation-fill-mode: forwards;
    animation-delay: 2.75s;
}

.empty {
    width: 15.625vw;
    height: 28.334vw;
    border: 11px solid #393F3E;;
    border-radius: 25px;
    position: absolute;
    /* top: .65vw; */
    background-color: black;
    transition: .36s ease;
    box-shadow: 0 0 18px black;
    animation: borderhoverdark .36s ease;
    animation-fill-mode: forwards;
}

.carddeck .empty {
    transition-delay: 2s;
}

.main-card.back {
    opacity: 0;
    transition: .74s ease;
}

.main-card.back .fixBox {
    width: 19px;
    height: auto;
    position: unset;
    place-content: flex-end;
}

.main-card.back .fixBox > * {
    width: fit-content;
}

.firstSlide .main-card.back .light,
.secondSlide .main-card.back .light {
    animation: moveLight 1.1s ease forwards;
    animation-delay: 1s;
}

@keyframes moveLight {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.23);
    }

    100% {
        transform: scale(1);
    }
}


@media screen and (min-width: 1024px) {

    .empty {
        box-shadow: none;
    }

    .main-card.back .light {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        transform: translateX(-29%);
    }


}

@media screen and (max-width: 1024px) {
    .main-card.back .card-name {
        font-size: 13px;
    }

    .main-card.back div p {
        font-size: 21px;
    }

    .main-card.back .card-name:first-of-type {
        padding-left: 10px;
    }

    .third .main-card.back p {
        padding-left: 2px;
    }

    .main-card.back div:first-of-type {
        padding-left: 2px;
    }

    .main-card.back .light {
        /* position: absolute;
        right: -125%; */
    }

    .main-card.back div .card-number {
        font-size: 11px;
    }
    
}