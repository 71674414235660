.dateform {
    position: absolute;
    z-index: -1;
    background: black;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    width: 780px;
    padding: 70px 120px;
    flex-direction: column;
    opacity: 0;
}

.date .dateform {
    z-index: 9;
    animation: appear .74s ease;
    animation-fill-mode: forwards;
    animation-delay: .25s;
}

.date .slider {
    z-index: -1;
}

.third .dateform {
    z-index: -1;
    transition-delay: 1s;
    animation: disappear .74s ease;
    animation-fill-mode: forwards;
}

@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes disappear {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.dateform p:first-of-type {
    font-family: 'Raleway';
    text-transform: uppercase;
    font-size: 19px;
    letter-spacing: 8.36px;
    line-height: 1.6vw;
    margin-bottom: 40px;
}

.dateform p:last-of-type {
    margin-bottom: 50px;
}

.dateform p.desktop {
    margin-bottom: 50px;
}

/* .dateform .selectbox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
}

.dateform .selectbox div {
    display: flex;
}

.dateform .selectbox select {
    background: none;
    color:var(--grey-color);
    font-size: 20px;
    padding: 15px 15px;
    text-transform: uppercase;
    font-family: 'Lato Light';
    letter-spacing: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
}

.dateform .selectbox div:first-child {
    width: 25.5%;
}

.dateform .selectbox div:nth-child(2) {
    width: 45.5%;
}

.dateform .selectbox div:last-child {
    width: 25.5%;
}

.dateform select:focus {
    border-color: var(--grey-color);
    box-shadow: none;
}

.dateform select option {
    border: none;
    background: black;
}

.dateform select::-ms-expand {
    display: none;
}

    

.dateform .selectbox div::after {
    content: '\02C5';
    font-family: 'Lato';
    position: relative;
    right: 30px;
    top: 17px;
    color: var(--green-color);
    border: none;
}

.selectbox select:hover {
    color: var(--green-color);
}

.selectbox select option:focus {
    color: white;
} */

.dateform .buttonbox {
    width: 98.5%;
    display: flex;
}

.dateform button:not(.react-date-picker button) {
    color: var(--green-color);
    height: 66px;
    width: 230px;
    letter-spacing: 3.52px;
    text-transform: uppercase;
    font-size: 16px;
    transition: .74s ease;
    padding: 0 0 2px 2px;
}

.dateform button:first-child {
    margin-right: 50px;
    border-bottom: 1px solid var(--green-color);
}

.dateform button:last-child {
    border: 1px solid var(--green-color);
}

.dateform button:hover {
    box-shadow: 0 0 22px var(--green-color);
}

/* Date-Picker */

.react-date-picker {
    width: 95%;
    height: 65px;
    transition: .74s ease;
    margin-bottom: 50px;
}

.green .react-date-picker__wrapper:hover {
    border-color: var(--green-color);
}

.react-date-picker__inputGroup {
    width: 25%;
}

.green .react-date-picker__wrapper {
    border: 1px solid;
    border-color: #393F3E;
    transition: .34s ease;
}

.react-date-picker__inputGroup__leadingZero,
.react-date-picker__inputGroup__input {
    color: var(--grey-color);
}

.react-calendar span,
.react-calendar button,
.react-calendar__month-view__weekdays__weekday {
    color: var(--green-color);
}

.green .react-date-picker__inputGroup__input {
    outline: none;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
}

.green .react-date-picker__wrapper {
    flex-grow: 0;
    width: 100%;
}

.green .react-date-picker__wrapper button:nth-child(2) {
    order: 3;
    margin-right: 10px;
}

.green .react-date-picker__wrapper button:nth-child(2) {
    display: none;
}

.green .react-date-picker__wrapper button:nth-child(3):hover {
    box-shadow: none;
}

.green .react-date-picker__wrapper button:nth-child(3) {
    border: none;
    margin-left: 10px;
}

.react-date-picker__inputGroup {
    order: 2;
    margin-left: 5px;
}

.react-date-picker__clear-button react-date-picker__button {
    order: 3;
}

.react-date-picker * {
    stroke: var(--green-color);
}

.react-date-picker__inputGroup__divider {
    margin: 5px;
    color: white;
}

.green .react-calendar {
    background-color: black;
    border: 1px solid rgba(59, 255, 225, .35);
}

/* .green .react-date-picker__calendar {
    left: 0;
    right: 0;
    margin: auto;
} */

.green .react-calendar__month-view__days__day--neighboringMonth {
    color: rgba(59, 255, 225, .35);;
}

.green .react-calendar__tile--active {
    background-color: #07211D;
    color: var(--green-color);
}

.green .react-calendar__navigation button:enabled:hover, 
.green .react-calendar__navigation button:enabled:focus {
    box-shadow: none;
    border-color: var(--green-color);
    background-color: unset;
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next-button,
.react-calendar__navigation__next2-button {
    font-size: 22px;
}

.green .react-calendar button {
    margin: 0;
    border: 1px solid rgba(59, 255, 225, .35);
}

.green .react-calendar button abbr:focus {
    color: black;
}

.green .react-calendar__tile:enabled:hover {
    border-color: var(--green-color);
    background-color: unset;
    box-shadow: none;
}

.green .react-calendar__tile--hasActive,
.green .react-calendar__navigation button[disabled] {
    background-color: rgba(59, 255, 225, .35)
}

.green .react-calendar__tile--now {
    background-color: black;
}


@media screen and (max-width: 1024px) {
    .dateform {
        padding: 0;
        width: 97vw;
        padding: 30px 35px 0;
        height: 79.275vh;
        bottom: -10%;
    }

    .dateform p:first-child {
        line-height: 30px;
        margin-bottom: 10px;
    }

    .dateform p.mobile {
        margin-bottom: 30px;
        line-height: 24px;
    }

    .react-date-picker {
        width: 103.875%;
        height: 58px;
        margin-bottom: 25px;
    }

    .green .react-date-picker__wrapper button:nth-child(3) {
        margin-left: 15px;
    }

    .react-date-picker__inputGroup {
        margin-left: 5px;
    }

    .green .react-date-picker__inputGroup__input {
        width: unset;
    }

    .green .react-date-picker__inputGroup__input,
    .react-date-picker__inputGroup__leadingZero {
        /* bottom: 1px;
        position: relative; */
        font-size: 13px;
        /* letter-spacing: .9px; */
        font-family: 'Lato Light';
        color: white;
    }

    .react-date-picker__inputGroup__divider {
        margin: 3px;
        /* position: relative;
        bottom: 1px; */
    }

    .dateform .buttonbox {
        flex-direction: column-reverse;
        width: 103.875%;
    }

    .dateform .buttonbox button {
        font-family: 'Lato Light';
    }

    .dateform button:not(.react-date-picker button) {
        font-size: 13px;
        height: 58px;
        width: auto;
    }

    .dateform .buttonbox button:first-child {
        margin: 0;
    }

    .dateform .buttonbox button:last-child {
        margin-bottom: 40px;
    }

    .dateform .datePickerWrapper {
        width: 103.5%;
        height: auto;
        display: flex;
    }

    .dateform .check {
        width: 20%;
        height: 58px;
        border: 1px solid var(--green-color);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dateform .check svg path {
        stroke: var(--green-color);
    }
  }