.Dynamic-box {
    position: absolute;
    z-index: 2;
    width: 83.334%;
    height: 74.305vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition-delay: .8s;
    transition: .74s ease;
    /* background-color: black; */
}

.second .Dynamic-box,
.third .Dynamic-box {
    transition-delay: 0s;
    transition: 0s;
}

.light .Dynamic-box {
    background-color: transparent;
}


.MainBox {
    display: flex;
    height: inherit;
    flex-direction: column;
    padding: 2.5vw 4vw 4.5vw;
    position: relative;
    overflow: hidden;
    transition: .34s ease-out;
    justify-content: flex-end;
    /* opacity: 0; */
    /* z-index: -1; */
    /* margin-top: 5vh; */
}


@media screen and (max-width: 1024px) {

    .Dynamic-box {
        align-items: center;
        width: 100%;
        height: 85vh;
        /* overflow: hidden; */
        transition: .36s ease;
        background-color: transparent;
        top: 6px;
    }

    .second .Dynamic-box {
        transition: .36s ease;
    }

    .third .Dynamic-box {
        transition: .36s ease;
    }

    .light .Dynamic-box, 
    .firstSlide .Dynamic-box,
    .secondSlide .Dynamic-box {
        /* overflow: hidden; */
    }

    .MainBox {
        justify-content: flex-end;
        width: 100%;
        overflow: hidden;
        padding: 0;
    }

    .MainBox .left,
    .MainBox .right,
    .MainBox h3 {
        padding: 0 25px 0 35px;
    }


    .swiper-container {
        height: 100%;
        position: absolute;
        /* display: flex; */
        width: 100%;
        place-content: center;
    }

    .swiper-wrapper {
        height: 100%;
        transition-duration: 1s;
    }

  }


  @media screen and (min-width: 1024px) {

    .second .Dynamic-box,
    .third .Dynamic-box {
    background-color: black;
    }


  }